import { Container } from "react-bootstrap";
import { HOME_PATH } from "../components/core/nav/NavigationItems";
import Layout from "../components/core/Layout";
import { Link } from "gatsby";
import React from "react";

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h2 className="text-uppercase h-muted">404</h2>
      <h1 className="text-uppercase h bolder">Seite nicht gefunden</h1>
      <Link to={HOME_PATH}>Zur Startseite</Link>
    </Container>
  </Layout>
);

export default NotFoundPage;
